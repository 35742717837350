<template>
  <div>
    <v-layout wrap justify-start>
      <!-- <v-flex xs12 text-left>
        <span style="font-size: 22px; font-family: poppinssemibold"
          >Get Involved</span
        >
      </v-flex> -->
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 lg11>
        <v-layout wrap justify-space-between py-2>
          <v-flex v-for="(item, i) in navItems" :key="i" px-2>
            <!-- <router-link :to="'/getInvolvedStatic?content=' + item.name"> -->
            <v-btn
              block
              text
              :ripple="false"
              @click="loadStatic(item)"
              :style="{
                border:
                  contents == item.name
                    ? '1px solid #ffe200'
                    : !contents &&
                      contents == 'Donate to the Cause of Conservation'
                    ? '1px solid #ffe200'
                    : 'none',
              }"
            >
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 14px;
                  color: #343434;
                  text-transform: none;
                "
              >
                {{ item.name }}
              </span>
            </v-btn>
            <!-- </router-link> -->
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <GetInvolvedContent :content="contents" :routeName="routeNames" />
        <!-- <GetInvolvedContent
          v-if="
            $route.query.content ==
            'Corporate Social Responsibility (CSR) Partners'
          "
          routeName="csr"
        />
        <GetInvolvedContent
          v-if="$route.query.content == 'Volunteering'"
          routeName="volunteering"
        /> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import GetInvolvedContent from "./getInvolvedContent";
export default {
  components: {
    GetInvolvedContent,
  },
  data() {
    return {
      contents: null,
      routeNames: null,
      navItems: [
        { name: "Donate to the Cause of Conservation", routeName: "donate" },
        {
          name: "Corporate Social Responsibility (CSR) Partners",
          routeName: "csr",
        },
        { name: "Volunteering", routeName: "volunteering" },
      ],
    };
  },
  mounted() {
    this.loadStatic(this.navItems[0]);
  },
  methods: {
    loadStatic(item) {
      this.routeNames = item.routeName;
      this.contents = item.name;
      // console.log(this.routeNames);
      // console.log(this.contents);
    },
  },
};
</script>